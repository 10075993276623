<template>
  <div>
    <page-title title="Complaint" :breadcrumbs-items="breadcrumbsItems"></page-title>

    <v-row class="mb-4">
      <v-col>
        <v-btn :to="{ name: 'ComplaintTicketCreate' }" class="mx-1" color="primary">
          Create Ticket
        </v-btn>
      </v-col>
    </v-row>

    <v-card :loading="loading">
      <v-card-title>
        Ticket
        <v-spacer></v-spacer>
        <div style="max-width:300px">
          <v-text-field v-model="searchBox" clearable append-icon="mdi-magnify" autofocus dense hide-details outlined flat single-line rounded></v-text-field>
        </div>
        <v-btn icon rounded color="primary" small @click="getData(true)" class="mx-1">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="dataitems" class="elevation-1" :search="searchBox">
        <template v-slot:[`item.ticket_no`]="{ item }" v-if="!isMobileBrowser()">
          <a href="javascript:;" @click="getDetail(item.ticket_no)">{{ item.ticket_no }}</a>
        </template>
        <template v-slot:[`item.priority`]="{ item }" v-if="!isMobileBrowser()">
          <PriorityChip :value="item.priority" small></PriorityChip>
        </template>
        <template v-slot:[`item.status`]="{ item }" v-if="!isMobileBrowser()">
          <StatusChip :value="item.status" small></StatusChip>
        </template>

        <template v-slot:[`item`]="{ item }" v-if="isMobileBrowser()">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1" v-text="item.ticket_no"></v-card-title>
            <v-card-subtitle class="my-0 py-0">{{ formatDate(item.created_date) }}</v-card-subtitle>
            <v-card-text>
              <v-sheet min-height="60">{{ item.subjects }}</v-sheet>
            </v-card-text>
            <v-card-actions class="d-flex align-center flex-row mx-2">
              <StatusChip :value="item.status"></StatusChip>
              <v-spacer></v-spacer>
              <v-btn outlined rounded color="primary" @click="getDetail(item.ticket_no)">Detail</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import PriorityChip from './ticket/PriorityChip.vue'
import StatusChip from './ticket/StatusChip.vue'

export default {
  components: {
    PriorityChip,
    StatusChip,
  },
  beforeMount() {
    this.requiredLogin()
  },
  mounted() {
    this.setTitle('Complaint')

    this.getData()
  },
  data() {
    return {
      loading: true,
      options: {},
      headers: [
        { text: 'No.', value: 'ticket_no' },
        { text: 'Subjects', value: 'subjects' },
        { text: 'Created Date', value: 'created_date' },
        { text: 'Last Modified Date', value: 'last_modified_date' },
        { text: 'Priority', value: 'priority', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
      ],
      searchBox: '',
      totaldataitems: 0,
      dataitems: [],
      datadetail: {},
      breadcrumbsItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/',
        },
        {
          text: 'Complaint',
          disabled: true,
          href: '#',
        },
      ],
    }
  },
  watch: {},
  methods: {
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove('GET', 'ticket/data')

      this.loading = true
      this.$axios
        .get('ticket/data', { cacheConfig: true })
        .then(response => {
          this.dataitems = response.data.data.tickets
          this.loading = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
        })
        .then(() => {
          this.loading = false
        })
    },
    getDetail(ticket_no) {
      this.redirect('ComplaintTicketDetail', { ticket_no: ticket_no})
    },
  },
}
</script>
