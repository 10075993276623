var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{attrs:{"title":"Complaint","breadcrumbs-items":_vm.breadcrumbsItems}}),_c('v-row',{staticClass:"mb-4"},[_c('v-col',[_c('v-btn',{staticClass:"mx-1",attrs:{"to":{ name: 'ComplaintTicketCreate' },"color":"primary"}},[_vm._v(" Create Ticket ")])],1)],1),_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Ticket "),_c('v-spacer'),_c('div',{staticStyle:{"max-width":"300px"}},[_c('v-text-field',{attrs:{"clearable":"","append-icon":"mdi-magnify","autofocus":"","dense":"","hide-details":"","outlined":"","flat":"","single-line":"","rounded":""},model:{value:(_vm.searchBox),callback:function ($$v) {_vm.searchBox=$$v},expression:"searchBox"}})],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","rounded":"","color":"primary","small":""},on:{"click":function($event){return _vm.getData(true)}}},[_c('v-icon',[_vm._v("mdi-sync")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataitems,"search":_vm.searchBox},scopedSlots:_vm._u([(!_vm.isMobileBrowser())?{key:"item.ticket_no",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.getDetail(item.ticket_no)}}},[_vm._v(_vm._s(item.ticket_no))])]}}:null,(!_vm.isMobileBrowser())?{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('PriorityChip',{attrs:{"value":item.priority,"small":""}})]}}:null,(!_vm.isMobileBrowser())?{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('StatusChip',{attrs:{"value":item.status,"small":""}})]}}:null,(_vm.isMobileBrowser())?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"ma-1",attrs:{"elevation":"4","outlined":""}},[_c('v-card-title',{staticClass:"text-h5 mb-1 pb-1",domProps:{"textContent":_vm._s(item.ticket_no)}}),_c('v-card-subtitle',{staticClass:"my-0 py-0"},[_vm._v(_vm._s(_vm.formatDate(item.created_date)))]),_c('v-card-text',[_c('v-sheet',{attrs:{"min-height":"60"}},[_vm._v(_vm._s(item.subjects))])],1),_c('v-card-actions',{staticClass:"d-flex align-center flex-row mx-2"},[_c('StatusChip',{attrs:{"value":item.status}}),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.getDetail(item.ticket_no)}}},[_vm._v("Detail")])],1)],1)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }